html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.rotating-icon {
  animation: rotating-icon-spin infinite 1s linear;
}

@keyframes rotating-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
